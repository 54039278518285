import React from 'react';
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare";
import { FaTwitterSquare } from "@react-icons/all-files/fa/FaTwitterSquare";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaWhatsapp } from "@react-icons/all-files/fa/FaWhatsapp";
import { SiTiktok } from "@react-icons/all-files/si/SiTiktok";
import styled from 'styled-components';

const SocialListStyles = styled.ul`
	margin:0;
	padding:0;
	text-align:center;
	list-style:none;
	color:#FFFFFF;
	display:flex;
	/* flex:1 0 0%; */
	flex-wrap:nowrap;
	justify-content:center;
	align-items:center;
	font-size:1.8rem;
	li{
		margin-right:1.5rem;
		&:last-child{
			margin-right:0;
		}
	}
	@media (max-width: 900px) {
		ul{
			margin: 1.25rem auto;
		}
  }
`;

const SocialLink = styled.a`
	padding:0;
	margin:0;
	text-decoration:none;
	color:var(--white);
	transition: all 0.2s;
	&:hover{
		color:var(--red);
	}
`;

const SocialLinks = () => {
	return (
		<SocialListStyles>
				<li>
					<SocialLink href="https://www.facebook.com/TradeTribeNorthEast" title="Trade Tribe's Facebook page">
						<FaFacebookSquare />
					</SocialLink>
					
				</li>
				{/* <li>
					<SocialLink href="https://www.twitter.com" title="Trade Tribe's Twitter page">
						<FaTwitterSquare />
					</SocialLink>
					
				</li>
				<li>
					<SocialLink href="https://www.linkedin.com" title="Trade Tribe's LinkedIn page ">
						<FaLinkedin />
					</SocialLink>
					
				</li> */}
				<li>
					{/* instagram */}
					<SocialLink href="https://www.instagram.com/trade.tribe/" title="Trade Tribe's Instagram page">
						<FaInstagram />
					</SocialLink>
					
				</li>
				{/* <li>
					<SocialLink href="https://www.whatsapp.com" title="Trade Tribe's WhatsApp details?">
						<FaWhatsapp />
					</SocialLink>
					
				</li>
				<li>
					<SocialLink href="https://www.tiktok.com" title="Trade Tribe's TikTok account">
						<SiTiktok />
					</SocialLink>
					
				</li> */}
			</SocialListStyles>
	);
};

export default SocialLinks;