import React from 'react';
import Header from './Header'
import Footer from './Footer'
import 'normalize.css';
import favicon from '../images/favicon.ico'
import Helmet from 'react-helmet'
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import styled from "styled-components";


const MainStyles = styled.main`
	flex:1 0 0%;
`;

const Layout = ({ children }) => {
	return (
		<>
			<Helmet>
      	<link rel="icon" href={favicon} />
				<title>Trade Tribe</title>
    	</Helmet>
			<GlobalStyles />
			<Typography />
			<Header />
			<MainStyles>
				{children}
			</MainStyles>
			<Footer />
		</>
	);
};

export default Layout;