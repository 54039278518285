import styled from 'styled-components';

export const CentralStyle = styled.div`
	max-width:860px;
	margin:0 auto;
	@media (max-width: 900px) {
		max-width:100%;
		margin: 0 6rem;
	}
`;
export const VerticalSpace = styled.div`
	margin:0 auto;
	height:3rem;
	@media (max-width: 900px) {

	}
`;

export const CentralCnt  = styled.div`
	margin:0 6rem;
	@media (max-width: 900px) {
		margin:0 0.5rem;
	}
`;