import { createGlobalStyle } from 'styled-components';


const Typography = createGlobalStyle`
  html {
    font-family: "Poppins", sans-serif;
    color: var(--white);
  }
  p, li {
    letter-spacing: 0.5px;
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: 700;
    margin: 1.5rem 0;
  }
  a {
    color: var(--black);
    text-decoration-color: var(--red);
    /* Chrome renders this weird with this font, so we turn it off */
    /* text-decoration-skip-ink: none; */
  }
  mark, .mark {
    background: var(--yellow);
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
  }
  .center {
    text-align: center;
  }
	p.red,span.red,h1.red,h2.red,h3.red,h4.red,h5.red,h6.red {
    color:var(--red);
  }
	p.yellow,span.yellow,h1.yellow,h2.yellow,h3.yellow,h4.yellow,h5.yellow,h6.yellow {
    color:var(--yellow);
  }
	p.blue,span.blue,h1.blue,h2.blue,h3.blue,h4.blue,h5.blue,h6.blue {
    color:var(--blue);
  }
	p.orange,span.orange,h1.orange,h2.orange,h3.orange,h4.orange,h5.orange,h6.orange {
    color:var(--orange);
  }
	p.green,span.green,h1.green,h2.green,h3.green,h4.green,h5.green,h6.green {
    color:var(--green);
  }
	p.pink,span.pink,h1.pink,h2.pink,h3.pink,h4.pink,h5.pink,h6.pink {
    color:var(--pink);
  }
	p.black,span.black,h1.black,h2.black,h3.black,h4.black,h5.black,h6.black {
    color:var(--black);
  }
	p.white,span.white,h1.white,h2.white,h3.white,h4.white,h5.white,h6.white {
    color:var(--white);
  }
`;

export default Typography;