import React from 'react';
import styled from 'styled-components';
import logoSVG from '../images/logo.svg';
const LogoStyles = styled.div`
	width:200px;
	height:38px;
`;

const Logo = () => {
	return (
		<LogoStyles>
			<img src={logoSVG} alt="Logo" />
		</LogoStyles>
	);
};

export default Logo;