import React from 'react';
import styled from 'styled-components';
import Logo from "./Logo";
import SocialLinks from './SocialLinks';
const HeaderStyles = styled.header`
	background-color: var(--black);
	height:75px;
	/* width:100%; */
	display:flex;
	flex-direction:row;
	flex-wrap:nowrap;
	justify-content:space-between;
	align-items:center;
	padding:0 5rem;
	.spacer{
		display:flex;
		flex:1 0 0%;
	}
	.logo{
		width:200px;
	}
	.social-links{
		display:flex;
		flex:1 0 0%;
		flex-wrap:nowrap;
		justify-content:flex-end;
		align-items:center;
	}


	@media (max-width: 900px) {
    flex-direction:column;
		justify-content:center;
		height:95px;
		.social-links, .spacer{
			display:none;
		}
  }
`;


const Header = () => {
	return (
		<HeaderStyles>
			<div className="spacer"></div>
			<Logo className="logo"/>
			<div className="social-links">
				<SocialLinks />
			</div>
		</HeaderStyles>
	);
};

export default Header;