import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #E26152;
		--green: #67C380;
    --yellow: #F5C25F;
		--blue: #55B1E6;
		--pink: #C550B1;
		--orange: #E57A39;
    --white: #ffffff;
		--black: #000000;
    --grey: #efefef;
		/*  */
		--redRGB: 226, 97, 82;
		--greenRGB: 103, 195, 128;
    --yellowRGB: 245, 194, 95;
		--blueRGB: 85, 177, 230;
		--pinkRGB: 197, 80, 177;
		--orangeRGB: 229, 122, 57;
		--blackRGB: 0, 0, 0;
  }
  html {
    font-size: 10px;
  }
  body {
    font-size: 2rem;
		background-color:var(--black);
		@media (max-width: 900px) {
			font-size: 1.9rem;
		} 
		/* @media (max-width: 420px) {
			font-size: 1.8rem;
		} */

  }

	body,
	#gatsby-focus-wrapper{
		display: flex;
		min-height: 100vh;
		flex-direction: column
	}
	
  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }
  button {
    /* background: var(--${props => props.colour | 'red'}); */
		background-color:var(--yellow);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    /* --cast: 2px;
    box-shadow: var(--cast) var(--cast) 0 var(--grey); */
    /* text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2); */
    transition: all 0.2s;
    /* &:hover {
      --cast: 4px;
    } */

  }
  /* .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  } */
  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--red) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--red) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }
  hr {
    border: 0;
    height: 8px;
  }
  img {
    max-width: 100%;
  }
  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }
`;

export default GlobalStyles;