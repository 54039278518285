import React from 'react';
import styled from 'styled-components';
import { CentralStyle, VerticalSpace } from '../styles/Layout';
import SocialLinks from './SocialLinks';
const FooterStyles = styled.footer`
	height:200px;
	font-size:1.3rem;
`;
const Footer = () => {
	return (
		<FooterStyles>
			<CentralStyle>
				<p className="center">&copy; Trade Tribe {new Date().getFullYear()} - Cowboys need not apply</p>
				<SocialLinks />
			</CentralStyle>
		</FooterStyles>
	);
};

export default Footer;